import { registerOnReadyHandler } from "@nadinewest/common"
import client from "braintree-web/client"
import hostedFields from "braintree-web/hosted-fields"

registerOnReadyHandler("customers-edit_cc", () => {
  /**************************************************************************/
  /*** Configuration ***/
  /**************************************************************************/
  let finixForm = null
  let hostedFieldsInstance = null

  // Retrieve Finix environment and merchant ID from data attributes
  const finixEnv = document.getElementById("finix-fields")?.dataset.env || "sandbox"
  const finixApplicationId = document.getElementById("finix-fields")?.dataset.applicationId || ""

  /**************************************************************************/
  /*** Finix Form ***/
  /**************************************************************************/
  function initializeFinixForm() {
    if (!finixApplicationId) {
      console.error("Finix Merchant ID is missing")
      return
    }

    const options = {
      showAddress: false,
      showLabels: true,
      labels: { name: "Cardholder Name" },
      showPlaceholders: true,
      placeholders: { name: "Cardholder Name" },
      hideFields: ["address_line1", "address_line2", "address_city", "address_region", "address_state", "address_country", "address_postal_code"],
      requiredFields: ["name"],
      hideErrorMessages: false,
      errorMessages: { name: "Please enter a valid name" },
      styles: {
        default: { color: "#000", border: "1px solid #CCCDCF", borderRadius: "8px", padding: "8px 16px", fontFamily: "Helvetica", fontSize: "16px" },
        error: { border: "1px solid rgba(255,0,0, 0.3)" },
      },
      onUpdate: (state, binInformation, formHasErrors) => {
        console.log("Finix Form Updated:", { state, binInformation, formHasErrors })
      },
      onLoad: () => {
        const spinner = document.querySelector("#finix-form-element #spinner");
        if (spinner) {
          spinner.style.display = "none";
        }
      }
    }

    finixForm = window.Finix.CardTokenForm("finix-form-element", options)
  }

  function handleFinixSubmission() {
    if (!finixForm) {
      alert("Finix is not initialized yet!")
      return
    }

    finixForm.submit(finixEnv, finixApplicationId, (err, res) => {
      if (err) {
        console.error("Finix Submission Error:", err)
        alert("Finix payment failed. Please try again.")
        return
      }

      const token = res?.data?.id
      if (!token) {
        alert("Failed to retrieve token from Finix")
        return
      }

      document.getElementById("payment_method_nonce").value = token
      document.getElementById("credit-card-form").submit()
    })
  }

  if (document.getElementById("finix-fields")) {
    initializeFinixForm()
  }

  /**************************************************************************/
  /*** Braintree Hosted Fields ***/
  /**************************************************************************/
  function initializeBraintree(hostedFieldsErr, instance) {
    if (hostedFieldsErr) {
      console.error("Braintree Hosted Fields Error:", hostedFieldsErr)
      return
    }
    hostedFieldsInstance = instance
  }

  function createBraintreeClient(token) {
    client.create({ authorization: token }, (clientErr, clientInstance) => {
      if (clientErr) {
        console.error("Braintree Client Error:", clientErr)
        alert("Failed to initialize Braintree Client")
        return
      }

      hostedFields.create(
        {
          client: clientInstance,
          styles: { ".invalid": { color: "#a94442" }, ".valid": { color: "green" } },
          fields: {
            number: { selector: "#cc-number", placeholder: "Credit Card Number" },
            cvv: { selector: "#cc-cvv", placeholder: "CVV" },
            expirationDate: { selector: "#cc-exp", placeholder: "MM/YY" },
          },
        },
        initializeBraintree
      )
    })
  }

  function handleBraintreeSubmission() {
    if (!hostedFieldsInstance) {
      alert("Braintree is not initialized yet!")
      return
    }

    hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
      if (tokenizeErr) {
        console.error("Braintree Tokenization Error:", tokenizeErr)
        alert("Failed to tokenize credit card info")
        return
      }

      document.getElementById("payment_method_nonce").value = payload.nonce
      document.getElementById("credit-card-form").submit()
    })
  }

  fetch("/braintree/token")
    .then(response => response.text())
    .then(createBraintreeClient)
    .catch(err => console.error("Braintree Token Fetch Error:", err))

  /**************************************************************************/
  /*** Payment Processor Selection ***/
  /**************************************************************************/
  function togglePaymentFields(selectedProcessor) {
    document.getElementById("braintree-fields").style.display = selectedProcessor === "braintree" ? "block" : "none"
    document.getElementById("finix-fields").style.display = selectedProcessor === "finix" ? "block" : "none"
    document.getElementById("selected-processor").value = selectedProcessor
  }

  document.querySelectorAll(".toggle-payment-processor").forEach(input =>
    input.addEventListener("change", () => togglePaymentFields(input.value))
  )

  // Set initial payment visibility
  togglePaymentFields(document.getElementById("selected-processor").value)

  /**************************************************************************/
  /*** Handle Submission ***/
  /**************************************************************************/
  document.getElementById("submit-cc-button")?.addEventListener("click", event => {
    event.preventDefault()
    const selectedProcessor = document.getElementById("selected-processor").value

    if (selectedProcessor === "braintree") {
      handleBraintreeSubmission()
    } else if (selectedProcessor === "finix") {
      handleFinixSubmission()
    }
  })
})
